* {
  box-sizing: border-box;
}

html,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #454545;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0
}

code {
  font-family: "Nunito Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
